import Logo from './Logo'

const styles = {
    chatIntro: {
        textAlign: 'center',
        padding: '50px 0',
        flexGrow: 1,
    },
    chatIntroLogo: {
        width: '50px',
        display: 'inline-flex'
    },
}

const ChatIntro = ({ user={ name: 'Aryan' } }) => {
    return (
        <div className="chatIntro" style={styles.chatIntro}>
            <div style={styles.chatIntroLogo}>
                <Logo/>
            </div>
            <h1 style={{ margin: 0 }}>
                {`Hej ${user.name},`}
                <br/>
                How can we help our customers today?
            </h1>
        </div>
    )
}

export default ChatIntro