import Card from './Card'

const sugg = [{
    driver: 'Customer experience',
    prompt: 'Show me key customer pain-points in Delft'
}, {
    driver: 'Customer support',
    prompt: 'How to set up a generous exchange and return policy?'
}, {
    driver: 'Insights & performance',
    prompt: 'How to improve customer loyalty in 3 steps?'
}]

const colors = ['#ADCBEB', '#FAEC9B', '#F5D7F6']

const styles = {
    newChatSuggestions: {
        paddingBottom: '40px',
        paddingRight: '7vw',
        marginTop: 'auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: '10px'
    }
}

const NewChatSuggestions = ({ suggestions=sugg, sendPrompt }) => {
    return (
        <div className="new-chat-suggestions" style={styles.newChatSuggestions}>
            {suggestions.map((suggestion, index) => (
                <Card
                    key={index}
                    tag={suggestion.driver}
                    text={suggestion.prompt}
                    color={colors[index]}
                    sendPrompt={sendPrompt}
                />
            ))}
        </div>
    )
}

export default NewChatSuggestions
