import { useRef, useState, useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import chatContext from "../context"
import ChatFeed from "./ChatFeed"
import ChatInputForm from "./ChatInputForm"
import ChatIntro from "./ChatIntro"
import NewChatSuggestions from "./NewChatSuggestions"

const styles = {
    chat: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overscrollBehavior: 'contain',
        width: '100%',
        paddingTop: '30px',
        paddingBottom: '20px'
    },
    mainPanel: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '7vw',
        boxSizing: 'border-box',
        height: '100%',
        position: 'relative',
        justifyContent: 'space-between',
    },
}

const Chat = () => {
    const chatEl = useRef(null)
    const chatFeedEnd = useRef()
    const { conversation, setActivePrompt, sendPrompt, setSendingPrompt, sendingPrompt, getConversation, setConversation } = useContext(chatContext)
    const { conversationId } = useParams()
    const isNew = conversationId === 'new' || !conversationId

    const scrollToBottom = () => {
        if (chatEl.current) {
            chatEl.current.scrollTop = chatEl.current.scrollHeight
        }
    }

    const isScrolledToBottom = () => {
        if (chatEl.current) {
            return Math.ceil(chatEl.current.scrollTop + chatEl.current.offsetHeight) === chatEl.current.scrollHeight
        }
        return false
    }

    const _sendPrompt = ({prompt, ...rest}) => {
        if (!prompt) return

        setSendingPrompt(true)
        setActivePrompt(prompt)
        
        sendPrompt({
            prompt,
            conversationId: isNew ? null : conversationId,
            ...rest
        })

        setTimeout(() => {
            scrollToBottom()
        }, 100)
    }

    useEffect(() => {
        if (conversationId !== 'new' && conversationId !== conversation?.id) {
            getConversation({ conversationId, onSuccess: scrollToBottom })
        }
    }, [conversationId])

    useEffect(() => {
        if (isNew) setConversation(null)
    }, [isNew])

    return (
        <div style={styles.mainPanel}>
            {!isNew || sendingPrompt ?
                <div className='chat' style={styles.chat} ref={chatEl}>
                    <ChatFeed
                        scrollToBottom={scrollToBottom}
                        isScrolledToBottom={isScrolledToBottom}
                    />
                    <div ref={chatFeedEnd} />
                </div>
            :
                <>
                    <ChatIntro/>
                    <NewChatSuggestions sendPrompt={_sendPrompt} />
                </>
            }
            <ChatInputForm
                scrollToBottom={scrollToBottom}
                isScrolledToBottom={isScrolledToBottom}
                sendPrompt={_sendPrompt}
                isNew={isNew}
            />
        </div>
    )
}

export default Chat
