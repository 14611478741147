import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'

const API_URL = process.env.REACT_APP_API_BASE_URL
const WS_URL = process.env.REACT_APP_WS_URL
export const chatContext = React.createContext({
    unlocked: localStorage.getItem('keypass') === process.env.REACT_APP_KEYPASS,
    user: {
        id: 1,
        name: 'Aryan',
        lastName: 'Aras',
        fullName: 'Aryan Aras',
        avatar: 'https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671142.jpg?w=1380&t=st=1711302961~exp=1711303561~hmac=14c0d96daa6716706897a36193b0449e2e61480186006a008c87207e8ed00bf3',
        jobTitle: 'Management',
    }
})

export const ChatProvider = ({children}) => {
    const { Provider } = chatContext
    const { user, unlocked: _unlocked } = useContext(chatContext)
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(`${WS_URL}/llm_stream/`, {
        shouldReconnect: () => true,
        retryOnError: true
    })
    const [conversations, setConversations] = useState([])
    const [loadingConversations, setLoadingConversations] = useState(false)
    const [loadingConversationsError, setLoadingConversationsError] = useState('')
    const [conversation, setConversation] = useState(null)
    const [loadingConversation, setLoadingConversation] = useState(false)
    const [loadingConversationError, setLoadingConversationError] = useState('')
    const [sendingPrompt, setSendingPrompt] = useState(false)
    const [sendingPromptError, setSendingPromptError] = useState('')
    const [responseStream, setResponseStream] = useState('')
    const [unlocked, unlock] = useState(_unlocked)
    const [activePrompt, setActivePrompt] = useState('')
    const userQueryString = `?user_id=${user.id}`
    const navigate = useNavigate()
    const wsConnectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]

    const unlockApp = (keypass) => {
        if (keypass === process.env.REACT_APP_KEYPASS) {
            localStorage.setItem('keypass', keypass)
            unlock(true)
        } else {
            alert('Invalid keypass. Please try again.')
        }
    }

    const getConversations = async () => {
        setLoadingConversations(true)
        try {
            const res = await fetch(`${API_URL}/conversations/${userQueryString}`)
            const data = await res.json()
            setConversations(data)
            if (loadingConversationsError) setLoadingConversationsError('')
        } catch (error) {
            setLoadingConversationsError(error)
        }
        setLoadingConversations(false)
    }

    const getConversation = async ({ conversationId, onSuccess }) => {
        setLoadingConversation(true)
        try {
            const res = await fetch(`${API_URL}/conversations/${conversationId}/`)
            if (res.status === 404) {
                setLoadingConversation(false)
                alert(`Conversation with id ${conversationId} not found`)
                return
            }
            const data = await res.json()
            setConversation(data)
            if (onSuccess) onSuccess(data)
            if (loadingConversationError) setLoadingConversationError('')
        } catch (error) {
            setLoadingConversationError(error)
        }
        setLoadingConversation(false)
    }

    const deleteConversation = async (id) => {
        try {
            const res = await fetch(`${API_URL}/conversations/${id}/${userQueryString}`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            })
            if (res.status !== 204) throw new Error()
            setConversations(conversations.filter(conversation => conversation.id !== id))
            if (conversation?.id === id) {
                setConversation(null)
                navigate('/chat/new')
            }
        } catch (error) {
            alert('Error deleting conversation')
        }
    }

    const renameConversation = async (id, name) => {
        try {
            const res = await fetch(`${API_URL}/conversations/${id}/${userQueryString}`, {
                method: 'PATCH',
                body: JSON.stringify({ name }),
                headers: {'Content-Type': 'application/json'}
            })
            const data = await res.json()
            setConversations(conversations.map(conversation => {
                if (conversation.id === id) conversation.name = data.name
                return conversation
            }))
            if (conversation?.id === id) setConversation({ ...conversation, name: data.name })
        } catch (error) {
            alert('Error renaming conversation')
        }
    }

    const sendPrompt = useCallback(({ prompt, conversationId }) => {
        if (!prompt) return
        setSendingPrompt(true)
        sendMessage(JSON.stringify({
            prompt,
            userId: user.id,
            conversationId
        }))
    }, [])

    useEffect(() => {
        if (!conversations?.length && !loadingConversations) getConversations()
    },[])

    useEffect(() => {
        if (lastMessage?.data) {
            const data = JSON.parse(lastMessage.data)
            if (data.error) {
                setSendingPromptError(data.error)
                if (window.confirm(`Error \"${data.error}\" occured during response. Please try again.`)) {
                    setSendingPrompt(false)
                    setSendingPromptError('')
                }
            } else {
                let type = data.type || 'text'
                let content = data.content || ''
                const codeBlockPrefix = `<pre class="${type}"><code>`
                const codeBlockSuffix = `</code></pre><span data-code-type="${type}"></span>`
                const codeBlockSuffixLength = codeBlockSuffix.length
                
                setResponseStream((prev) => {
                    let text = prev.toString()
                    let newContent = content.toString()
                    const codeBlockRunning = prev.slice(prev.length - codeBlockSuffixLength) === codeBlockSuffix
                    
                    if (type.includes('code')) {
                        // open code and pre tags if previous chunk was not code_interpreter
                        if (!codeBlockRunning) {
                            newContent = `${codeBlockPrefix}${content}${codeBlockSuffix}`
                        } else {
                            // if previous chunk was code_interpreter move pre and code closing tags to the end
                            text = text.slice(0, prev.length - codeBlockSuffixLength)
                            newContent = `${content}${codeBlockSuffix}`
                        }
                    }
                    return text + newContent
                })

                if (data.finish_reason === 'stop') {
                    const isNew = !!data.is_new
                    setConversation(data.conversation)
                    setSendingPrompt(false)
                    setActivePrompt('')
                    setResponseStream('')
                    if (sendingPromptError) setSendingPromptError('')
                    if (isNew) {
                        setConversations([data.conversation, ...conversations])
                        navigate(`/chat/${data.conversation.id}`)
                    }
                }
            }
        }
    }, [lastMessage])

    useEffect(() => {
        if (readyState === ReadyState.CLOSED || readyState === ReadyState.UNINSTANTIATED) {
            console.log('Reconnecting...')
            getWebSocket()   
        }
    }, [readyState])

   return(
       <Provider value={{
            user,
            activePrompt,
            setActivePrompt,
            unlocked,
            unlockApp,
            getConversations,
            conversations,
            loadingConversations,
            loadingConversationsError,
            getConversation,
            setConversation,
            deleteConversation,
            renameConversation,
            conversation,
            loadingConversation,
            loadingConversationError,
            sendPrompt,
            sendingPrompt,
            setSendingPrompt,
            sendingPromptError,
            responseStream,
            setResponseStream,
            wsConnectionStatus,
        }}>
           {children}
       </Provider>
   )
}

export default chatContext
