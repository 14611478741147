import { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import ConversationsList from './ConversationsList'
import Icon from './Icon'
import { IkeaLogo } from './Logo'
import chatContext from '../context'


const styles = {
    sidePanel: {
        width: '19%',
        minWidth: '260px',
        maxWidth: '350px',
        borderRadius: '28px',
        backgroundColor: '#BCD9F8',
        boxShadow: '0px 64px 64px -32px #003B7266',
        maxHeight: 'calc(100vh - 80px)',
        display: 'flex',
        flexDirection: 'column',
        padding: '36px 24px 24px',
        boxSizing: 'border-box',
        marginTop: '30px',
    },
    sidePanelHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    sidePanelHeaderImg: {
        width: '52px',
        height: '52px',
        borderRadius: '50%',
        marginRight: '6px',
    },
    sidePanelJobTitle: {
        color: '#002C52',
        fontSize: '11px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
    },
    sidePanelFullName: {
        fontSize: '16px',
        fontWeight: '700',
        margin: '10px 0 0',
    },
    divider: {
        height: '1px',
        margin: '20px',
        background: 'radial-gradient(50% 50% at 50% 50%, #432C2C 0%, rgba(80, 28, 28, 0) 100%)',
        border: 'none',
        opacity: '0.24',
    },
    unstyledList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    unstyledLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    sidePanelMenuItem: {
        padding: '16px 20px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '12px',
        cursor: 'not-allowed',
        color: '#002C528F',
        fontWeight: '500',
    },
    sidePanelMenuItemActive: {
        background: '#0058ABCC',
        color: '#FFFFFF',
        fontWeight: '700',
        cursor: 'pointer',
    },
    conversationsHeader: {
        fontSize: '11px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        padding: '0 20px',
    },
    elipsisText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    yellowButton: {
        backgroundColor: '#FBD914',
        padding: '10px 20px',
        borderRadius: '12px',
        border: 'none',
        boxShadow: '0px 4px 24px 0px #CC64064D',
        alignSelf: 'center',
        margin: 'auto auto 10px',
        color: '#002C52',
        fontWeight: '600',
        fontSize: '16px',
        height: '48px',
        width: 'calc(100% - 40px)',
        cursor: 'pointer',
    },
    sidePanelFooter: {
        display: 'flex',
        justifyContent: 'center',
    },
}


const SidePanel = () => {
    const { user } = useContext(chatContext)
    let navigate = useNavigate() 

    return (
        <div className="side-panel" style={styles.sidePanel}>
            <div className="side-panel__header" style={styles.sidePanelHeader}>
                <img src={user.avatar} alt="avatar" style={styles.sidePanelHeaderImg}/>
                <div className="side-panel__header__info">
                    <div style={styles.sidePanelJobTitle}>{user.jobTitle}</div>
                    <h3 style={styles.sidePanelFullName}>{user.fullName}</h3>
                </div>
            </div>
            <hr style={styles.divider}/>
            <ul className="side-panel__menu" style={styles.unstyledList}>
                {['Chat', 'Performance', 'Profile', 'Settings'].map((item, index) => (
                    <li
                        key={index}
                        style={{...styles.sidePanelMenuItem, ...index === 0 ? styles.sidePanelMenuItemActive : {}}}
                        // onClick={() => navigate('/'+item.toLowerCase())}
                    >
                        <Icon name={item.toLowerCase()}/>
                        <div style={{ marginLeft: '20px' }}>{item}</div>
                    </li>
                ))}
            </ul>
            <hr style={styles.divider}/>
            <h3 style={styles.conversationsHeader}>Recent chats</h3>
            <ConversationsList/>
            <button
                className="side-panel__new-chat"
                style={styles.yellowButton}
                onClick={() => navigate('/chat/new')}
            >
                New Chat<span style={{ marginLeft: '16px' }}><Icon name="plus"/></span>
            </button>
            <hr style={styles.divider}/>
            <div className="side-panel__footer" style={styles.sidePanelFooter}>
                <IkeaLogo />
            </div>
        </div>
    )
}

export default SidePanel