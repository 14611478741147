import { useContext } from "react"
import chatContext from "../context"

const cardStyles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        borderRadius: '24px',
        minHeight: '150px',
    },
    cardTag: {
        color: '#2E3A4499',
        fontSize: '16px',
        fontWeight: '700',
    },
    cardText: {
        marginTop: 'auto',
        fontSize: '22px',
        fontWeight: '700',
    }
}


const Card = ({ tag, text, color, sendPrompt }) => {
    const { sendingPrompt, wsConnectionStatus } = useContext(chatContext)
    const isDisabled = sendingPrompt || wsConnectionStatus !== 'Open'

    return (
        <div
            className={`chat__card ${isDisabled ? 'chat__card--disabled' : ''}`}
            style={{ ...cardStyles.card, backgroundColor: color }}
            onClick={isDisabled ? undefined : () => {
                sendPrompt({ prompt: text, filters: { driver: tag } })
            }}
        >
            <div className='chat__card__tag' style={cardStyles.cardTag}>
                {tag}
            </div>
            <div className='chat__card__text' style={cardStyles.cardText}>
                {text}
            </div>
        </div>
    )
}

export default Card
