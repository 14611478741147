import { Outlet } from "react-router-dom"
import { useContext } from 'react'
import { chatContext } from '../context'
import SidePanel from './SidePanel'
import ChatInputForm from "./ChatInputForm"

const pageStyles = {
    unlockWrapper: {
      position: 'fixed',
      top: '40vh',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '800px',
    },
    wrapper: {
        display: 'flex',
        height: '100vh',
        padding: '0 0 40px 40px',
        boxSizing: 'border-box',
        color: '#002C52',
    }
}

const Page = () => {
  const { unlocked, unlockApp } = useContext(chatContext)

  if (!unlocked) return (
    <div style={pageStyles.unlockWrapper}>
      <ChatInputForm
        placeholder='Enter your keypass to unlock the app'
        sendPrompt={({ prompt }) => unlockApp(prompt)}
        isKeyhole
      />
    </div>
  )

  return (
    <div style={pageStyles.wrapper}>
      <SidePanel />
      <Outlet />
    </div>
  )
}

export default Page