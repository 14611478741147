import { useRef, useState, useContext } from "react"
import Icon from "./Icon"
import chatContext from "../context"

const INPUT_HEIGHT = '72px'

const chatInputStyles = {
    form: {
        height: 'auto',
        // marginTop: 'auto',
        marginBottom: '10px',
        position: 'sticky',
        bottom: '0px',
        display: 'flex',
        marginRight: '7vw',
        width: 'calc(100% - 7vw)',
        background: '#A4C9F14D',
        borderRadius: '28px',
        boxShadow: '0px 44px 64px -32px rgba(0, 59, 114, 0.5)',
        padding: '24px',
    },
    textarea: {
        flexGrow: 1,
        height: INPUT_HEIGHT,
        borderRadius: '36px',
        padding: '24px 20px',
        border: '1.5px solid #002C5266',
        background: 'rgb(250, 250, 250)',
        resize: 'none',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'inherit',
    },
    submitButton: {
        marginRight: 0,
        marginLeft: '16px',
        width: INPUT_HEIGHT,
        height: INPUT_HEIGHT,
        background: 'linear-gradient(0deg, #FBD914, #FBD914)',
        borderRadius: '50%',
        border: 'none',
        boxShadow: '0px 4px 24px 0px #C5AC1680',
        padding: '21px',
        alignSelf: 'flex-end',
    },
}

const PROMPT_PLACEHOLDER = 'Continue your conversation ...'
const NEW_CHAT_PROMPT_PLACEHOLDER = 'Type your question or click one above...'

const ChatInputForm = ({
    scrollToBottom,
    isScrolledToBottom=()=>{},
    sendPrompt,
    isNew,
    placeholder,
    isKeyhole,
}) => {
    const [prompt, setPrompt] = useState('')
    const promptInput = useRef()
    const { sendingPrompt: isResponding, wsConnectionStatus } = useContext(chatContext)

    const growTextArea = () => {
        promptInput.current.style.height = INPUT_HEIGHT
        if (promptInput.current.scrollHeight) {
            const wasChatScrolledToBottom = isScrolledToBottom()
            promptInput.current.style.height = promptInput.current.scrollHeight + 'px'
            wasChatScrolledToBottom && scrollToBottom()
        }
    }

    const resetPrompt = () => {
        promptInput.current.value = ''
        promptInput.current.style.height = INPUT_HEIGHT
        setPrompt('')
    }

    const submitPrompt = (e) => {
        e.preventDefault()
        sendPrompt({ prompt })
        resetPrompt()
    }

    return (
        <form
            className='chat__prompt-form'
            onSubmit={submitPrompt}
            style={chatInputStyles.form}
        >
            <textarea
                ref={promptInput}
                style={chatInputStyles.textarea}
                tabIndex={0}
                placeholder={placeholder ? placeholder : isNew ? NEW_CHAT_PROMPT_PLACEHOLDER : PROMPT_PLACEHOLDER}
                onChange={e => {
                    const { value } = e.target
                    setPrompt(value)
                }}
                onKeyUp={growTextArea}
                onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey && !e.altKey) {
                        submitPrompt(e)
                        promptInput.current.blur()
                    }
                }}
            />
            <button
                type='submit'
                className={`chat__prompt-submit${isResponding ? ' animate' : ''}`}
                style={chatInputStyles.submitButton}
                disabled={!prompt || (!isKeyhole && (isResponding || wsConnectionStatus !== 'Open'))}
            >
                <Icon name='send'/>
            </button>
        </form>
    )
}

export default ChatInputForm
