import React, { useContext, useEffect, useState } from "react"
import chatContext from "../context"
import TypingDots from "./TypingDots"
import ChatMessage, { messageStyles } from "./ChatMessage"
import ChatbotLogo from "./Logo"

const throttle = (func, limit) => {
    let inThrottle;
    return function() {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => inThrottle = false, limit)
        }
    }
}

const ResponseStream = ({ scrollToBottom, isScrolledToBottom }) => {
    const { responseStream } = useContext(chatContext)
    const [preventAutomatedScroll, setPreventScroll] = useState(false)

    // listen to user scroll up
    useEffect(() => {
        const handleScroll = throttle((e) => {
            // prevent automated scroll to botom if user scrolled upward
            // reanable automated scroll if user scrolls back to bottom
            if (e.deltaY < -2) {
                setPreventScroll(true)
            } else if (e.deltaY > 2 && !!isScrolledToBottom()) {
                setPreventScroll(false)
            }
        }, 200)
        document.querySelector('.chat').addEventListener('wheel', handleScroll)
        return () => document.querySelector('.chat').removeEventListener('wheel', handleScroll)
    }, [])

    useEffect(() => {
        if (responseStream && !preventAutomatedScroll) scrollToBottom()
    }, [responseStream])

    if (responseStream) return (
        <>
            <ChatMessage
                message={{ role: 'assistant', content: responseStream }}
                isTyping
            />
            <TypingDots style={{ marginLeft: '70px' }} />
        </>
    )

    return (
        <div style={messageStyles.messageWrapper}>
            <div style={messageStyles.senderIcon}>
                <ChatbotLogo size='40' isAnimated />
            </div>
            <TypingDots style={{ marginTop: '32px' }} />
        </div>
    )
}

export default ResponseStream
