import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import chatContext from "../context"

const styles = {
    conversationsList: {
        padding: 0,
        margin: '0 -10px 24px 0',
        overflowY: 'auto',
    },
    conversationsItem: {
        color: '#002C528F',
        fontSize: '14px',
        lineHeight: '20px',
        padding: '4px 32px 0 20px',
        fontWeight: '500',
        position: 'relative',
    },
    elipsisText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    unstyledLink: {
        textDecoration: 'none',
        color: 'inherit',
        width: '100%',
        display: 'inline-block',
    },
    activeLink: {
        fontWeight: '800',
        color: 'rgb(0, 44, 82)',
    },
    iconButtons: {
        position: 'absolute',
        right: '0',
    },
    iconButton: {
        background: 'none',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: '0',
        margin: '0',
    }
}

const ConversationsList = () => {
    const { conversations, deleteConversation, renameConversation } = useContext(chatContext)
    const { conversationId } = useParams()
    
    return (
        <ul className="side-panel__conversations" style={styles.conversationsList}>
            {conversations.map((conversation, index) => {
                const isActive = `${conversation.id}` === conversationId
                
                return (
                    <li key={index} style={{...styles.conversationsItem, ...styles.elipsisText}}>
                        <Link
                            to={'/chat/'+ conversation.id}
                            style={{...styles.unstyledLink, ...styles.elipsisText, ...(isActive && styles.activeLink)}}
                            disabled={isActive}
                            title={conversation.name}
                        >
                            {conversation.name || 'Untitled Chat'}
                        </Link>
                        <div className='side-panel__conversations__icon-buttons' style={styles.iconButtons}>
                            <button
                                title='rename'
                                style={styles.iconButton}
                                onClick={() => {
                                    const newName = window.prompt('Enter a new title for this conversation:', conversation.name)
                                    newName && newName !== conversation.name && renameConversation(conversation.id, newName)
                                }}
                            >
                                🖌️
                            </button>
                            <button
                                title='delete'
                                style={{...styles.iconButton, marginLeft: '5px'}}
                                onClick={() => {
                                    window.confirm(`Are you sure you want to delete conversation titled "${conversation.name}"?`) &&
                                    deleteConversation(conversation.id)
                                }}
                            >
                                🗑️
                            </button>
                        </div>
                    </li>
                )
                })} 
        </ul>
    )
}

export default ConversationsList
