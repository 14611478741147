const TypingDots = (props) => {
    return (
        <div className="typing" {...props}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default TypingDots
