import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Chat from './components/Chat'
import { ChatProvider } from './context'
import Page from './components/Page'

function App() {
  return (
    <BrowserRouter>
      <ChatProvider>
        <Routes>
          <Route path='/' element={<Page/>}>
            <Route index element={<Chat/>} />
            <Route path='chat' element={<Chat/>}>
              <Route path=':conversationId' element={<Chat/>} />
            </Route>
          </Route>
        </Routes>
      </ChatProvider>
    </BrowserRouter>
  )
}

export default App
