import React, { useContext, useEffect } from "react"
import TypingDots from "./TypingDots"
import ChatMessage from "./ChatMessage"
import chatContext from "../context"
import ResponseStream from "./ResponseStream"

const handleImageClick = (e) => {
    const imgSrc = e.target.src
    const modal = document.createElement('div')
    modal.style.position = 'fixed'
    modal.style.top = '0'
    modal.style.left = '0'
    modal.style.width = '100%'
    modal.style.height = '100%'
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'
    modal.style.display = 'flex'
    modal.style.justifyContent = 'center'
    modal.style.alignItems = 'center'
    modal.style.zIndex = '1000'
    modal.onclick = () => modal.remove()
  
    const img = document.createElement('img')
    img.src = imgSrc
    img.style.maxWidth = '100%'
    img.style.maxHeight = '100%'
    img.style.objectFit = 'contain'
    img.style.cursor = 'pointer'
    modal.appendChild(img)
    document.body.appendChild(modal)
}

const styles = {
    loaderWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-22.5px)'
    }
}

const ChatFeed = ({
    isScrolledToBottom,
    scrollToBottom,
}) => {
    const {
        activePrompt,
        conversation,
        responseStream,
        sendingPrompt: isResponding,
        sendingPromptError,
        loadingConversation: isLoading,
        loadingConversationError
    } = useContext(chatContext)

    const error = sendingPromptError || loadingConversationError

    useEffect(() => {
        if (responseStream && isScrolledToBottom()) {
            scrollToBottom()
        }
    }, [responseStream])

    useEffect(() => {
        const chatEl = document.getElementsByClassName('chat')[0]
        chatEl?.querySelectorAll('img').forEach(img => {
            img.onclick = handleImageClick
        })
        chatEl?.querySelectorAll('pre.input--text').forEach(codeEl => {
            const caret = document.createElement('button')
            caret.innerHTML = '⌃'
            caret.classList.add('caret--code-toggle')
            caret.onclick = () => codeEl.classList.toggle('code-block--collapsed')
            codeEl.appendChild(caret)
        })
        scrollToBottom()
    }, [conversation?.id, conversation?.messages?.length])

    return (
        <React.Fragment>
            {isLoading &&
                <div style={styles.loaderWrapper}>
                    <TypingDots style={{ transform: 'scale(2)' }} />
                </div>
            }
            {conversation && conversation.messages && conversation.messages.map((message, i) => {
                return (
                    <React.Fragment key={`${conversation.id}-msg-${i}`}>
                        <ChatMessage
                            isLast={i === conversation.messages.length - 1}
                            message={message}
                            conversation={conversation}
                            scrollToBottom={scrollToBottom}
                        />
                    </React.Fragment>
                )
            })}
            {activePrompt &&
                <ChatMessage
                    conversation={conversation}
                    message={{ content: activePrompt, role: 'user' }}
                />
            }
            {isResponding &&
                <ResponseStream
                    scrollToBottom={scrollToBottom}
                    isScrolledToBottom={isScrolledToBottom}
                />
            }
            {error &&
                <div style={{
                    ...styles.messageWrapper,
                    backgroundColor: 'rgba(232, 73, 88, 0.2)',
                }}>
                    {error}
                </div>
            }
        </React.Fragment>
    )
}

export default ChatFeed
