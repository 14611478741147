import React, { useContext } from 'react'
import { marked } from 'marked'
import { sanitize } from 'dompurify'
import chatContext from '../context'
import ChatbotLogo from './Logo'
import TypingDots from './TypingDots'

marked.setOptions({
  gfm: true,
  breaks: true,
  smartLists: true,
  smartypants: false
})


export const messageStyles = {
  messageWrapper: {
    padding: '14px 16px',
    display: 'flex',
    alignItems: 'flex-start',
    width: '82%',
    minWidth: '82%',
    maxWidth: '1000px',
  },
  senderIcon: {
    marginRight: '10px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'inline-flex',
  },
  message: {
    padding: '16px 20px 16px 20px',
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    borderRadius: '24px',
    borderTopLeftRadius: 0,
  }
}

const ChatMessage = ({
  message,
  isTyping,
}) => {
  const { user } = useContext(chatContext)
  const { role, content, is_forgotten } = message
  if (!content) return null
  const className = is_forgotten ? 'chat__message chat__message--forgotten' : 'chat__message'

  const senderIcon = (
    <div style={messageStyles.senderIcon} className='message__sender'>
      {role === 'assistant' ?
        <ChatbotLogo size='40' isAnimated={isTyping} />
      : role === 'system' ?
        <ChatbotLogo size='40' isAnimated={isTyping} />
      :
        <img src={user?.avatar} alt="avatar" style={messageStyles.senderIcon}/>
      }
    </div>
  )
  
  return (
    <div
      className={className}
      style={{
        ...messageStyles.messageWrapper,
        opacity: is_forgotten ? 0.6 : 1, 
      }}
    >
      {senderIcon}
      <div
        className='chat__message-content'
        style={{
          ...messageStyles.message,
          backgroundColor: role === 'assistant' ? 'rgba(0, 0, 0, 0.06)' : role === 'system' ? 'rgba(109, 193, 184, 0.1)' : role === 'user' ? 'rgba(47, 110, 177, 0.1)' : undefined,
          color: role === 'assistant' ? '#000000' : undefined,
        }}
        dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
      />
    </div>
  )
}

export default ChatMessage
